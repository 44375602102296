@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #556324; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #778A35; 
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.form-control{
  @apply bg-ext-darker border-black p-2 border rounded w-full
}

.btn-primary{
  @apply p-2 rounded text-white bg-ext-green
}
.btn-secondary{
  @apply p-2 rounded text-white bg-ext-green bg-opacity-50
}
.ol-geocoder .gcd-txt-control, .ol-geocoder.gcd-txt-container{
  height: 3em!important;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.ol-geocoder ul.gcd-txt-result{
  top: 3em!important;
}
.CjiV{
  background-color: #31352e!important;
}
.tnECc{
  padding: 0;
}
.ol-geocoder .gcd-txt-glass{
  top: 12px !important;
}